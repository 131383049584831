body {
  margin: 0;
  padding: 0;
  padding-bottom: 30px;
}
iframe {
  width: 100%;
}
button {
  border-radius: 0 !important;
}
.MuiContainer-root {
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.MuiAppBar-positionStatic {
  margin-bottom: 8px !important;
}
.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}
.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.video-overlay {
  height: 27%;
  width: 100%;
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
}
